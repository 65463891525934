import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import endpoints from './endpoints';
import domain from './domain';

Vue.use(Vuex);
// if api is running locally, use 'http://127.0.0.1:8000' as domain

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    authUser: {},
    isAuthenticated: false,
    jwt: '',
    endpoints: endpoints(domain),
    loadingState: false,
    msgDiag: false,
    msgDiagText: '',
    adminSnackbar: false,
    adminSnackbarText: '',
    fullname: '',
    profilePic: '',
    isAdmin: '',
    adminName: '',
    email: '',
    notificationConsent: false,
    ignoreConsentNotice: false,
    profile_percentage: 0,
    fields_to_apply: null,
  },
  mutations: {
    setAdmin(state, status) {
      Vue.set(state, 'isAdmin', status);
    },
    setAdminName(state, username) {
      Vue.set(state, 'adminName', username);
    },
    setLoginDetails(state, data) {
      Vue.set(state, 'fullname', data.full_name);
      Vue.set(state, 'profilePic', data.profile_pic);
      Vue.set(state, 'email', data.email);
      Vue.set(state, 'notificationConsent', data.notification_consent);
      Vue.set(state, 'ignoreConsentNotice', data.ignore_consent_notice);
      Vue.set(state, 'profile_percentage', data.profile_percentage);
      Vue.set(state, 'fields_to_apply', data.fields_to_apply);
    },
    setFieldToApply(state, data) {
      Vue.set(state, 'fields_to_apply', data.fields_to_apply);
    },
    adminSnackbarUp(state, message) {
      const desc = message[Object.keys(message)[0]];
      const trueDesc = (typeof desc === 'string' || desc instanceof String)
        ? desc : desc.pop();
      const trueMessage = (typeof message === 'string' || message instanceof String)
        ? message : `${Object.keys(message)[0]}: ${trueDesc}`;
      Vue.set(state, 'adminSnackbar', true);
      Vue.set(state, 'adminSnackbarText', trueMessage);
    },
    adminSnackbarDown(state) {
      Vue.set(state, 'adminSnackbar', false);
    },
    msgDiagPop(state, message) {
      Vue.set(state, 'msgDiagText', message);
      Vue.set(state, 'state.msgDiag', true);
    },
    msgDiagDismiss(state) {
      Vue.set(state, 'msgDiag', false);
    },
    setLoading(state) {
      Vue.set(state, 'loadingState', true);
    },
    unsetLoading(state) {
      Vue.set(state, 'loadingState', false);
    },
    setAuthUser(state, {
      authUser,
      isAuthenticated,
    }) {
      Vue.set(state, 'authUser', authUser);
      Vue.set(state, 'isAuthenticated', isAuthenticated);
    },
    updateToken(state, newToken) {
      Vue.set(state, 'jwt', newToken);
    },
    logout(state) {
      Vue.set(state, 'jwt', null);
      Vue.set(state, 'fullname', '');
      Vue.set(state, 'profilePic', '');
      Vue.set(state, 'isAdmin', false);
    },
  },
  actions: {

  },
});
