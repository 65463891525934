import Vue from 'vue';
import Router from 'vue-router';
import { parseJwt, JwtIsExpired } from '@/utils/jwt';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/a',
      component: () => import('./views/admin/Base.vue'),
      beforeEnter: (to, from, next) => {
        /**
         * Check to see if we were given the JWT token in the url (this happens when logging in
         * using OAUth, for example, when using the outlook365 account).
         */
        if (to.query.token) {
          store.commit('updateToken', to.query.token);
          store.commit('setAdmin', parseJwt(store.state.jwt).is_admin);
          next({ name: 'admin:offers' });
        } else if (to.query.tokenerror) {
          store.commit('adminSnackbarUp', to.query.tokenerror);
          next({ name: 'candidate:offers' });
        } else {
          next();
        }
      },
      children: [
        {
          path: 'home',
          name: 'admin:home',
          component: () => import('./views/admin/Home.vue'),
        },
        {
          path: 'archive',
          name: 'admin:archive',
          component: () => import('./views/admin/LonglistArchive.vue'),
        },
        {
          path: 'config',
          name: 'admin:config',
          component: () => import('./views/admin/AdminConfig.vue'),
        },
        {
          path: 'ofertas',
          name: 'admin:offers',
          component: () => import('./views/admin/Offers.vue'),
        },
        {
          path: 'ofertas/nueva',
          name: 'admin:new_offer',
          component: () => import('./views/admin/NewOffer.vue'),
        },
        {
          path: 'oferta/:uuid/postulantes',
          name: 'admin:offer_applicants',
          component: () => import('./views/admin/OfferApplicants.vue'),
        },
        {
          path: 'oferta/:uuid/extpostulantes',
          name: 'admin:offer_extapplicants',
          component: () => import('./views/admin/OfferExternalApplicants.vue'),
        },
        {
          path: 'candidatos',
          name: 'admin:candidates',
          component: () => import('./views/admin/CandidatesList.vue'),
        },
        {
          path: 'candidatos/new',
          name: 'admin:new_candidate',
          component: () => import('./views/admin/NewCandidate.vue'),
        },
        {
          path: 'candidato/:uuid',
          component: () => import('./views/admin/CandidatesDetail.vue'),
          children: [
            {
              path: 'postulaciones',
              name: 'admin:candidate:applications',
              component: () => import('./components/candidates/CandidateApplications.vue'),
            },
            {
              path: 'postulacion/:ohc_uuid',
              name: 'admin:application_detail',
              component: () => import('./views/admin/OfferHasCandidateDetail.vue'),
            },
            {
              path: 'ohc/history',
              name: 'admin:application_history',
              component: () => import('./views/admin/OHCHistory.vue'),
            },
            {
              path: 'mmdigital/evaluaciones',
              name: 'admin:mmdigital_evaluation_history',
              component: () => import('./views/admin/MMDigitalEvaluationHistory.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/embed-register',
      name: 'embed-register',
      component: () => import('./views/candidate/EmbedRegister.vue'),
    },
    {
      path: '/analistas',
      name: 'admin:login',
      component: () => import('./views/admin/Login.vue'),
    },
    {
      path: '/forgetpassword',
      name: 'forget-password',
      component: () => import('./views/candidate/ForgetPassword.vue'),
    },
    {
      path: '/newpassword',
      name: 'new-password',
      component: () => import('./views/candidate/NewPassword.vue'),
    },
    {
      path: '/',
      name: 'redirect',
      component: () => import('./views/candidate/MainRedirect.vue'),
    },
    {
      path: '/',
      name: 'faq',
      component: () => import('./views/candidate/FAQBase.vue'),
      children: [
        {
          path: 'faq',
          name: 'faq:list',
          component: () => import('./views/candidate/FAQList.vue'),
        },
        {
          path: 'faq/:faqid',
          name: 'faq:detail',
          component: () => import('./views/candidate/FAQDetail.vue'),
        },
      ],
    },
    {
      path: '/oferta/:uuid',
      name: 'candidate:offer:redirect-patch',
      component: () => import('./views/candidate/OfferRedirectPatchView.vue'),
    },
    {
      path: '',
      component: () => import('./views/candidate/Base.vue'),
      children: [
        {
          path: 'oauth2',
          name: 'login:oauth2:redirect-patch',
          component: () => import('./views/candidate/OAuthSignup.vue'),
          props: route => ({ userData: route.query }),
        },
        {
          path: 'privacy',
          name: 'privacy-policy:redirect-patch',
          component: () => import('./views/candidate/PrivacyPolicy.vue'),
        },
      ],
    },
    {
      path: '/:geo(CL|PE|CO)',
      component: () => import('./views/candidate/GeoBase.vue'),
      children: [
        {
          path: '',
          name: 'candidate:profile',
          component: () => import('./views/candidate/ProfileBase.vue'),
          children: [
            {
              path: 'profile',
              name: 'candidate:profile:detail',
              component: () => import('./views/candidate/Profile.vue'),
            },
            {
              path: 'settings',
              name: 'candidate:profile:settings',
              component: () => import('./views/candidate/ProfileSettings.vue'),
            },
            {
              path: 'applications',
              name: 'candidate:profile:applications',
              component: () => import('./views/candidate/MyApplications.vue'),
            },
            {
              path: 'chat/candidate/:uuid',
              name: 'candidate:profile:chat:candidate',
              component: () => import('./views/candidate/OHCNewMessageCandidate.vue'),
            },
            {
              path: 'chat/admin',
              name: 'candidate:profile:chat:admin',
              component: () => import('./views/candidate/MyApplications.vue'),
            },
            {
              path: 'favourites',
              name: 'candidate:profile:favourites',
              component: () => import('./views/candidate/MyFavourites.vue'),
            },
            {
              path: 'search',
              name: 'candidate:offers',
              component: () => import('./views/candidate/Offers.vue'),
            },
            {
              path: '',
              name: 'candidate:landing',
              component: () => import('./views/candidate/Landing.vue'),
            },
            {
              path: 'ATS',
              name: 'candidate:ats',
              component: () => import('./views/candidate/Ats.vue'),
            },
          ],
        },
        {
          path: '',
          component: () => import('./views/candidate/Base.vue'),
          name: 'home',
          children: [
            {
              path: 'oferta/:uuid',
              name: 'candidate:offer',
              component: () => import('./views/candidate/OfferView.vue'),
            },
            {
              path: 'postulacion/:uuid',
              name: 'candidate:offer:postulation',
              component: () => import('./views/candidate/CandidatePostulation.vue'),
            },
            {
              path: 'oauth2',
              name: 'login:oauth2',
              component: () => import('./views/candidate/OAuthSignup.vue'),
              props: route => ({ userData: route.query }),
            },
            {
              path: 'privacy',
              name: 'privacy-policy',
              component: () => import('./views/candidate/PrivacyPolicy.vue'),
            },
            {
              path: 'link-expired',
              name: 'link:expired',
              component: () => import('./views/candidate/LinkExpired.vue'),
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  /**
   * This little boy here does the redirection magic.
   * Since we're storing the 'store' in the localStorage, we can access these values from
   * anywhere within the app.
   *
   * All the navigation guards were moved here because putting them in the root component does not
   * work recursively for the children:
   * https://forum.vuejs.org/t/vue-router-beforeenter-doesnt-work-properly-for-children-path/20019/5
   *
   * Instead, we just define a beforeEach, and then check the route and handle it properly. There
   * are 2 main cases:
   *  - when it's an admin route
   *  - when it's anything else
   */
  // this solves an edge case where users enter a route, then the loading dialog appears because
  // it's loading a table or something, but they quickly go to another route. This causes the
  // loading dialog to never go away
  store.commit('unsetLoading');

  const jwt = parseJwt(store.state.jwt);
  if (to.fullPath.startsWith('/a/')) {
    // rules for admin routes
    if (jwt && JwtIsExpired(store.state.jwt)) {
      // token is expired => redirect to home page
      store.commit('updateToken', '');
      next({ name: 'candidate:offers' });
      store.commit('adminSnackbarUp', 'Su sesión expiró');
    } else if (!(jwt && jwt.is_admin)) {
      // not and admin => redirect home
      next({ name: 'candidate:offers' });
    } else {
      next();
    }
  } else {
    // rules for candidates and public routes
    if (jwt && JwtIsExpired(store.state.jwt)) {
      store.commit('updateToken', '');
      store.commit('adminSnackbarUp', 'Su sesión expiró');
    }
    next();
  }
});

export default router;
