/**
 * Endpoints that are used by the application.
 * */
const endpoints = domain => {
  const api = {
    auth: `${domain}/api/auth`,
    oauth: `${domain}/auth`,
    offers: {
      public: `${domain}/api/ofertas/public`,
      admin: `${domain}/api/ofertas/admin`,
      candidate: `${domain}/api/ofertas/candidate`,
      generic: `${domain}/api/ofertas`,
    },
    candidates: `${domain}/api/candidatos`,
    candidatesAdmin: `${domain}/api/candidatos/a`,
    users: `${domain}/api/users`,
  };

  return {
    obtainJWT: `${api.auth}/obtain_token/`,
    refreshJWT: `${api.auth}/refresh_token/`,
    passwordReset: `${domain}/api/password_reset/`,
    passwordConfirm: `${domain}/api/confirm/`,
    validateToken: `${domain}/api/validate/`,
    banner: `${domain}/api/marketing/banner/`,
    faq: {
      list: `${domain}/api/marketing/faq/`,
      detail: faqid => `${domain}/api/marketing/faq/${faqid}/`,
    },
    offers: {
      public: {
        landingOffers: `${api.offers.public}/landing_offers/`,
        list: `${api.offers.public}/`,
        countries: `${api.offers.public}/paises/`,
        regions: `${api.offers.public}/regiones/`,
        cities: `${api.offers.public}/ciudades/`,
        region_cities: `${api.offers.public}/region_ciudades/`,
        areas: `${api.offers.public}/areas/`,
        superAreas: `${api.offers.public}/super_areas/`,
        contracts: `${api.offers.public}/contracts/`,
        positionTiers: `${api.offers.public}/position_tiers/`,
        relatedOfferCount: (from, to) => `${api.offers.public}/related/${from}/${to}/`,
        get: uuid => `${api.offers.public}/${uuid}/`,
      },
      states: `${api.offers.generic}/ohc/states/`,
      listMessage: uuid => `${api.offers.generic}/ohc/message-list/${uuid}`,
      createMessage: `${api.offers.generic}/ohc/message-candidate/`,
      candidates: {
        apply: uuid => `${api.offers.candidate}/${uuid}/postular/`,
        favourite: uuid => `${api.offers.candidate}/${uuid}/favourite/`,
      },
      admin: {
        getAdmins: `${api.offers.admin}/admins`,
        archive: `${api.offers.generic}/archive/`,
        contracts: `${api.offers.admin}/get-contracts/`,
        position_tiers: `${api.offers.admin}/get-position-tiers/`,
        llCategories: `${api.offers.admin}/archive/categories/`,
        create: `${api.offers.admin}/`,
        list: `${api.offers.admin}/`,
        listHome: `${api.offers.admin}/list-home`,
        get: uuid => `${api.offers.admin}/${uuid}/`,
        toggleState: uuid => `${api.offers.admin}/${uuid}/cambiar_estado/`,
        activateOffer: uuid => `${api.offers.admin}/${uuid}/activate/`,
        closeOffer: uuid => `${api.offers.admin}/${uuid}/close/`,
        rejectOffer: uuid => `${api.offers.admin}/${uuid}/cerrar_oferta_marketplace/`,
        suspendOffer: uuid => `${api.offers.admin}/${uuid}/suspender/`,
        applicants: uuid => `${api.offers.admin}/${uuid}/postulantes/`,
        applicantsV2: uuid => `${api.offers.admin}/${uuid}/postulantes-v2/`,
        extApplicants: uuid => `${api.offers.admin}/${uuid}/extpostulantes`,
        application: uuid => `${api.offers.admin}/postulaciones/${uuid}/`,
        getNewExtcands: uuid => `${api.offers.admin}/${uuid}/get-newcands`,
        changePassword: `${api.users}/changepassword/`,
        activateAccount: `${api.users}/activate-account/`,
        resendVerification: `${api.users}/resend-verification/`,
        retrieveUpdateOHC: `${api.offers.admin}/ohc/`,
        retrieveUpdateOHCMatches: uuid => `${api.offers.admin}/ohc/matches/${uuid}`,
        calculateTestMatch: uuid => `${api.offers.admin}/ohc/calculate-test/${uuid}/`,
        getOHCHistory: uuid => `${api.offers.admin}/ohc/history/${uuid}/`,
        getOHCStates: uuid => `${api.offers.admin}/ohc/states/${uuid}`,
        getOHCTests: uuid => `${api.offers.admin}/ohc/tests/${uuid}`,
        uploadOHCReport: uuid => `${api.offers.admin}/ohc/report/${uuid}/`,
        getLLUrl: `${api.offers.admin}/longlist/generate/`,
        sendFeedback: `${api.offers.admin}/feedback`,
        lookupCandidates: filter => `${api.candidates}/admin/candidates?filter=${filter}`,
        offerQuestions: uuid => `${api.offers.admin}/questions?offer=${uuid}`,
        offerMultiChoiceQuestions: id => `${api.offers.admin}/list-multichoice?offer=${id}`,
        createOHC: `${api.offers.admin}/createohc`,
        perks: `${api.offers.admin}/perks`,
      },
    },
    candidates: {
      list: `${api.candidatesAdmin}/`,
      get: uuid => `${api.candidatesAdmin}/${uuid}/`,
      applications: uuid => `${api.candidatesAdmin}/${uuid}/postulaciones/`,
      sendDISC: uuid => `${api.candidatesAdmin}/${uuid}/send-disc/`,
      sendCognitive: uuid => `${api.candidatesAdmin}/${uuid}/send-cognitive/`,
      register: `${api.candidates}/register/`,
      getLoginData: `${api.candidates}/getCandidateData/`,
      studies: `${api.candidates}/studies/`,
      mmdigitalEvaluations: uuid => `${api.candidates}/mmdigital/evaluaciones/${uuid}/`,
      getSelf: `${api.candidates}/profile/`,
      updateProfile: `${api.candidates}/updateprofile/`,
      candidateApplication: filter => `${api.candidates}/profile/applications?filter=${filter}`,
      getOHC: uuid => `${api.candidates}/profile/applications/chat/${uuid}/`,
      candidateFavourites: `${api.candidates}/profile/favourites/`,
      getCHD: `${api.candidates}/profile/chd/`,
      changeCHD: uuid => `${api.candidates}/chd/${uuid}`,
      degrees: (country, search) => `${api.candidates}/degrees?country=${country}&filter=${search}`,
      workExperience: `${api.candidates}/profile/work-experience/`,
      changeWorkExperience: uuid => `${api.candidates}/work-experience/${uuid}`,
      companies: `${api.candidates}/companies/`,
      industries: `${api.candidates}/industries/`,
      institution: `${api.candidates}/institution/`,
    },
    linkedin: {
      callback: `${api.oauth}/auth/social/linkedin_oauth2/callback/`,
      getUrl: `${api.oauth}/api/rest-auth/linkedin/auth-server/`,
    },
    windowslive: {
      callback: `${api.oauth}/auth/social/windowslive/callback/`,
      getUrl: `${api.oauth}/api/rest-auth/windowslive/auth-server/`,
    },
    google: {
      callback: `${api.oauth}/auth/social/google/callback/`,
      getUrl: `${api.oauth}/api/rest-auth/google/auth-server/`,
    },
  };
};

export default endpoints;
