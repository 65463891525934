/**
 * Takes a JWT token and decodes it into an object.
 *
 * Taken from: https://stackoverflow.com/a/46188039
 * */
export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

/**
 * Whether a given token is expired or not.
 *
 * Taken from: https://stackoverflow.com/a/51293316
 * */
export const JwtIsExpired = token => {
  const jwt = parseJwt(token);
  if (jwt) {
    return jwt.exp < (Date.now() / 1000);
  }
  return true;
};

/**
 * Whether the token can be refreshed or not.
 * */
export const canRefreshToken = token => !JwtIsExpired(token);

/**
 * Returns how many seconds left the given token has. If it's expired, returns 0.
 * */
export const JwtRemainingSeconds = token => {
  const jwt = parseJwt(token);
  if (jwt && !JwtIsExpired(token)) {
    return Math.ceil(jwt.exp - (Date.now() / 1000));
  }
  return 0;
};

/**
 * Check if a given JWT should be refreshed because it's close to its expiration date. For admin
 * roles, if it has less than 3 days remaining it should be refreshed. For others, it's 1 day.
 */
export const shouldRefreshJwt = token => {
  const jwt = parseJwt(token);
  if (!canRefreshToken(token)) {
    // Should not refresh an expired token
    return false;
  }
  let refreshThreshold = 24 * 3600; // 1 day [seconds]
  if (jwt.is_admin) {
    // admins should refresh if it has less than 3 days remaining
    refreshThreshold = 3 * 24 * 3600; // 3 days [seconds]
  }
  return JwtRemainingSeconds(token) < refreshThreshold;
};
